// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .CreateUser-YardOwnerButton-button {
  position: fixed;
  top: 5%;
  left: 2%;
  transform: translate(0, -50%);
  z-index: 33;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 60%;
  background-color: #fc4817;
  border-radius: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/components/YardOwnerButton/YardOwnerButton.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,OAAO;EACP,QAAQ;EACR,6BAA6B;EAC7B,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,cAAc;EACd,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":["body .CreateUser-YardOwnerButton-button {\n  position: fixed;\n  top: 5%;\n  left: 2%;\n  transform: translate(0, -50%);\n  z-index: 33;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: white;\n  font-size: 60%;\n  background-color: #fc4817;\n  border-radius: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
