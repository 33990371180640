import { TextField, Typography } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { LuContainer } from "react-icons/lu";

const STInformation = ({
  payloadValidations,
  payload,
  contactNameRegex,
  setPayload,
  setPayloadValidations,
  emailRegex,
  scacValidate,
}) => {
  return (
    <Grid container spacing={3}>
      {" "}
      <Grid
        xs={12}
        sm={6}
        md={6}
        lg={3}
        xl={3}
        className="FormCreateUser-target-title-grid"
      >
        <Typography className="FormCreateUser-target-title">
          <LuContainer className="FormCreateUser-target-icon lu-container" />
          Street Turn
        </Typography>
      </Grid>
      <Grid xs={12} sm={6} md={6} lg={3} xl={3}>
        <TextField
          id="outlined-basic"
          label="ST Contact Name"
          variant="outlined"
          fullWidth
          disabled={scacValidate.existing}
          value={
            payload.Carrier.STInformation.Name
              ? payload.Carrier.STInformation.Name
              : ""
          }
          error={payloadValidations.Carrier?.STInformation?.Name}
          onChange={(e) => {
            const contactName = e.target.value;

            // Validar que el nombre de contacto siga la regex
            const isValidContactName = contactNameRegex.test(contactName);

            setPayload({
              ...payload,
              Carrier: {
                ...payload.Carrier,
                STInformation: {
                  ...payload.Carrier.STInformation,
                  Name: contactName,
                },
              },
            });

            setPayloadValidations({
              ...payloadValidations,
              Carrier: {
                ...payloadValidations.Carrier,
                STInformation: {
                  ...payloadValidations.Carrier.STInformation,
                  Name:
                    !isValidContactName || contactName.length > 0
                      ? false
                      : true,
                },
              },
            });
          }}
        />
      </Grid>
      <Grid xs={12} sm={6} md={6} lg={3} xl={3}>
        <TextField
          id="outlined-basic"
          label="ST Email"
          variant="outlined"
          fullWidth
          disabled={scacValidate.existing}
          value={
            payload.Carrier.STInformation.Email
              ? payload.Carrier.STInformation.Email
              : ""
          }
          error={payloadValidations.Carrier?.STInformation?.Email}
          onChange={(e) => {
            const email = e.target.value;

            setPayload({
              ...payload,
              Carrier: {
                ...payload.Carrier,
                STInformation: {
                  ...payload.Carrier.STInformation,
                  Email: email,
                },
              },
            });

            if (!emailRegex.test(email) || email.length === 0) {
              setPayloadValidations({
                ...payloadValidations,
                Carrier: {
                  ...payloadValidations.Carrier,
                  STInformation: {
                    ...payloadValidations.Carrier.STInformation,
                    Email: true,
                  },
                },
              });
            } else {
              setPayloadValidations({
                ...payloadValidations,
                Carrier: {
                  ...payloadValidations.Carrier,
                  STInformation: {
                    ...payloadValidations.Carrier.STInformation,
                    Email: false,
                  },
                },
              });
            }
          }}
        />
      </Grid>
      <Grid xs={12} sm={6} md={6} lg={3} xl={3}>
        <TextField
          id="outlined-basic"
          label="ST Phone Number"
          variant="outlined"
          fullWidth
          disabled={scacValidate.existing}
          value={
            payload.Carrier.STInformation.PhoneNumber
              ? payload.Carrier.STInformation.PhoneNumber
              : ""
          }
          placeholder="(123) 456-7890"
          error={payloadValidations.Carrier?.STInformation?.PhoneNumber}
          inputProps={{
            maxLength: 10,
            inputMode: "numeric", // Ensures numeric keyboard on mobile devices
          }}
          onChange={(e) => {
            const value = e.target.value;

            // Allow only digits
            if (!/^\d*$/.test(value)) {
              return;
            }

            setPayload({
              ...payload,
              Carrier: {
                ...payload.Carrier,
                STInformation: {
                  ...payload.Carrier.STInformation,
                  PhoneNumber: parseInt(value),
                },
              },
            });

            if (value.length === 10 || value.length === 0) {
              setPayloadValidations({
                ...payloadValidations,
                Carrier: {
                  ...payloadValidations.Carrier,
                  STInformation: {
                    ...payloadValidations.Carrier.STInformation,
                    PhoneNumber: false,
                  },
                },
              });
            } else {
              setPayloadValidations({
                ...payloadValidations,
                Carrier: {
                  ...payloadValidations.Carrier,
                  STInformation: {
                    ...payloadValidations.Carrier.STInformation,
                    PhoneNumber: true,
                  },
                },
              });
            }
          }}
        />
      </Grid>
    </Grid>
  );
};
export default STInformation;
