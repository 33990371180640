// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .TitleFormCreateUser-grid-image-draypower {
    justify-content: center;
    display: flex;
}

body .TitleFormCreateUser-image-draypower {
    width: 80%;
}


body .TitleFormCreateUser-welcome-text {
    justify-content: center;
    display: flex;
    font-size: 120%;
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
    color: #22224f;
}

body .TitleFormCreateUser-description-text {
    justify-content: center;
    display: flex;
    font-size: 120%;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    color: #22224f;
}

body .TitleFormCreateUser-grid-container {
    padding-bottom: 10%;
}

body .TitleFormCreateUser-typeOfUser-text{
    justify-content: center;
    display: flex;
    font-size: 300%;
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
    color: #fc4817;
}`, "",{"version":3,"sources":["webpack://./src/components/Title/Title.css"],"names":[],"mappings":"AAEA;IACI,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,UAAU;AACd;;;AAGA;IACI,uBAAuB;IACvB,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,iCAAiC;IACjC,cAAc;AAClB;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,iCAAiC;IACjC,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,iCAAiC;IACjC,cAAc;AAClB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');\n\nbody .TitleFormCreateUser-grid-image-draypower {\n    justify-content: center;\n    display: flex;\n}\n\nbody .TitleFormCreateUser-image-draypower {\n    width: 80%;\n}\n\n\nbody .TitleFormCreateUser-welcome-text {\n    justify-content: center;\n    display: flex;\n    font-size: 120%;\n    font-weight: 900;\n    font-family: 'Roboto', sans-serif;\n    color: #22224f;\n}\n\nbody .TitleFormCreateUser-description-text {\n    justify-content: center;\n    display: flex;\n    font-size: 120%;\n    font-weight: 400;\n    font-family: 'Roboto', sans-serif;\n    color: #22224f;\n}\n\nbody .TitleFormCreateUser-grid-container {\n    padding-bottom: 10%;\n}\n\nbody .TitleFormCreateUser-typeOfUser-text{\n    justify-content: center;\n    display: flex;\n    font-size: 300%;\n    font-weight: 900;\n    font-family: 'Roboto', sans-serif;\n    color: #fc4817;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
