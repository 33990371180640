import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Typography,
  Divider,
  Button,
  CircularProgress,
  Backdrop,
  Paper,
  Checkbox,
} from "@mui/material";
import { createUser, getCarrierByScac } from "../../services/userServices";
import { useSnackBar } from "../../context/SnackBarContext";
import BillingInformation from "../BillingInformation/BillingInformation";
import DispatchInformation from "../DispatchInformation/DispatchInformation";
import STInformation from "../STInformation/STInformation";
import UserInformation from "../UserInformation/UserInformation";
import "./FormCreateUser.css";
const FormCreateUser = () => {
  const { handleRenderSnackBar } = useSnackBar();
  const [scacValidate, setScacValidate] = React.useState({
    existing: true,
    loading: false,
    isSupra: false,
  });
  const [creatingUser, setCreatingUser] = React.useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const nameRegex = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/;
  const contactNameRegex = /^[a-zA-Z]+( [a-zA-Z]+)*$/;
  const cityDistrictRegex = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/;
  const stateProvinceRegex = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/;
  const [payload, setPayload] = React.useState({
    Carrier: {
      ScacCode: null,
      CompanyName: null,
      AcceptShareEmpties: true,
      Address: {
        AddressLine: null,
        City: null,
        State: null,
        ZipCode: null,
      },
      BillingInformation: {
        Name: null,
        Email: null,
        PhoneNumber: null,
      },
      STInformation: {
        Name: "",
        Email: "",
        PhoneNumber: 0,
      },
      DispatchInformation: {
        Name: "",
        Email: "",
        PhoneNumber: 0,
      },
    },
    NewUser: {
      UserId: null,
      UserName: null,
      Email: null,
      PhoneNumber: null,
      type: "Carrier",
    },
    ResourceToAuthorize: "Products",
  });

  const [payloadValidations, setPayloadValidations] = React.useState({
    Carrier: {
      ScacCode: false,
      CompanyName: false,
      Address: {
        AddressLine: false,
        City: false,
        State: false,
      },
      BillingInformation: {
        Name: false,
        Email: false,
        PhoneNumber: false,
      },
      STInformation: null,
      DispatchInformation: null,
    },
    NewUser: {
      UserId: false,
      UserName: false,
      Email: false,
    },
  });

  function validatePayload() {
    let validations = true;
    if (!payload.Carrier.ScacCode) {
      setPayloadValidations((prevValidations) => ({
        ...prevValidations,
        Carrier: { ...prevValidations.Carrier, ScacCode: true },
      }));
      validations = false;
    }
    if (!payload.Carrier.CompanyName) {
      setPayloadValidations((prevValidations) => ({
        ...prevValidations,
        Carrier: { ...prevValidations.Carrier, CompanyName: true },
      }));
      validations = false;
    }
    if (!payload.Carrier.Address.AddressLine) {
      setPayloadValidations((prevValidations) => ({
        ...prevValidations,
        Carrier: {
          ...prevValidations.Carrier,
          Address: { ...prevValidations.Carrier.Address, AddressLine: true },
        },
      }));
      validations = false;
    }
    if (!payload.Carrier.Address.City) {
      setPayloadValidations((prevValidations) => ({
        ...prevValidations,
        Carrier: {
          ...prevValidations.Carrier,
          Address: { ...prevValidations.Carrier.Address, City: true },
        },
      }));
      validations = false;
    }
    if (!payload.Carrier.Address.State) {
      setPayloadValidations((prevValidations) => ({
        ...prevValidations,
        Carrier: {
          ...prevValidations.Carrier,
          Address: { ...prevValidations.Carrier.Address, State: true },
        },
      }));
      validations = false;
    }
    if (!payload.Carrier.BillingInformation.Name) {
      setPayloadValidations((prevValidations) => ({
        ...prevValidations,
        Carrier: {
          ...prevValidations.Carrier,
          BillingInformation: {
            ...prevValidations.Carrier.BillingInformation,
            Name: true,
          },
        },
      }));
      validations = false;
    }
    if (!payload.Carrier.BillingInformation.Email) {
      setPayloadValidations((prevValidations) => ({
        ...prevValidations,
        Carrier: {
          ...prevValidations.Carrier,
          BillingInformation: {
            ...prevValidations.Carrier.BillingInformation,
            Email: true,
          },
        },
      }));
      validations = false;
    }
    if (!payload.Carrier.BillingInformation.PhoneNumber) {
      setPayloadValidations((prevValidations) => ({
        ...prevValidations,
        Carrier: {
          ...prevValidations.Carrier,
          BillingInformation: {
            ...prevValidations.Carrier.BillingInformation,
            PhoneNumber: true,
          },
        },
      }));
      validations = false;
    }
    if (!payload.NewUser.UserName) {
      setPayloadValidations((prevValidations) => ({
        ...prevValidations,
        NewUser: { ...prevValidations.NewUser, UserName: true },
      }));
      validations = false;
    }
    if (!payload.NewUser.Email) {
      setPayloadValidations((prevValidations) => ({
        ...prevValidations,
        NewUser: { ...prevValidations.NewUser, Email: true },
      }));
      return false;
    }
    return validations;
  }

  function handleCheckScacCode(scac, currentPayload) {
    setScacValidate({ existing: false, loading: true });
    getCarrierByScac(scac)
      .then((response) => {
        if (response) {
          setPayloadValidations({
            ...payloadValidations,
            Carrier: {
              ScacCode: false,
              CompanyName: false,
              Address: {
                AddressLine: false,
                City: false,
                State: false,
              },
              BillingInformation: {
                Name: false,
                Email: false,
                PhoneNumber: false,
              },
              STInformation: {
                Name: false,
                Email: false,
                PhoneNumber: false,
              },
              DispatchInformation: {
                Name: false,
                Email: false,
                PhoneNumber: false,
              },
            },
          });
          if (response.ScacCode === "SNEA") {
            setScacValidate({ existing: true, loading: false, isSupra: true });
          } else {
            setScacValidate({ existing: true, loading: false });
          }
          setPayload({
            ...currentPayload,
            Carrier: {
              ...currentPayload.Carrier,
              ScacCode: response.ScacCode,
              CompanyName: response.CarrierName,
              Address: {
                ...currentPayload.Carrier.Address,
                AddressLine: response.Address.AddressLine,
                City: response.Address.City,
                State: response.Address.State,
                ZipCode: response.Address.ZipCode,
              },
              BillingInformation: {
                ...currentPayload.Carrier.BillingInformation,
                Name: response.BillingContact.NameContact,
                Email: response.BillingContact.EmailContact,
                PhoneNumber: response.BillingContact.PhoneNumber,
              },
              STInformation: {
                ...currentPayload.Carrier.STInformation,
                Name: response.StContact?.NameContact,
                Email: response.StContact?.EmailContact,
                PhoneNumber: response.StContact?.PhoneNumber,
              },
              DispatchInformation: {
                ...currentPayload.Carrier.DispatchInformation,
                Name: response.DispatchContact?.NameContact,
                Email: response.DispatchContact?.EmailContact,
                PhoneNumber: response.DispatchContact?.PhoneNumber,
              },
              NewUser: {
                ...currentPayload.NewUser,
              },
            },
          });
          return;
        }
        setScacValidate({ existing: false, loading: false, isSupra: false });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function handleCreateUserApi() {
    const validations = validatePayload();
    if (!validations) return;

    setCreatingUser(true);

    await createUser(payload)
      .then((response) => {
        if (response.Errors) {
          const errors = response.Errors.map((error) => error.ErrorMessage);
          handleRenderSnackBar(errors, "error");
          setCreatingUser(false);
          return;
        }
        if (response.toString().includes("error")) {
          handleRenderSnackBar(response, "error");
          setCreatingUser(false);
          return;
        }
        handleRenderSnackBar("User created successfully", "success");
        setTimeout(() => {
          window.location.href = `${window.location.origin}/landing-page/home`;
        }, 3000);
      })
      .catch((error) => {
        console.error(error);
        handleRenderSnackBar("An error occurred, please try again", "error");
        setCreatingUser(false);
      });
  }

  function handleCleanInformation() {
    setPayload({
      ...payload,
      Carrier: {
        ...payload.Carrier,
        CompanyName: null,
        Address: {
          AddressLine: null,
          City: null,
          State: null,
          ZipCode: null,
        },
        BillingInformation: {
          Name: null,
          Email: null,
          PhoneNumber: null,
        },
        STInformation: {
          Name: null,
          Email: null,
          PhoneNumber: 0,
        },
        DispatchInformation: {
          Name: null,
          Email: null,
          PhoneNumber: 0,
        },
      },
    });
  }

  React.useEffect(() => {
    function handleSetb2cInfoToUser(e) {
      const userDetails = {
        UserName: e.detail.name,
        Email: e.detail.username,
        UserId: e.detail.idTokenClaims.sub,
      };

      if (e.detail.username && e.detail.username.includes("@snecorp")) {
        const updatedPayload = {
          ...payload,
          Carrier: {
            ...payload.Carrier,
            ScacCode: "SNEA",
          },
          NewUser: {
            ...payload.NewUser,
            ...userDetails,
          },
        };
        handleCheckScacCode("SNEA", updatedPayload);
      } else {
        const updatedPayload = {
          ...payload,
          NewUser: {
            ...payload.NewUser,
            ...userDetails,
          },
        };
        setPayload(updatedPayload);
      }
    }
    window.addEventListener("userInB2C", (e) => handleSetb2cInfoToUser(e));
    window.dispatchEvent(new CustomEvent("getUserData"));
  }, []);
  return (
    <>
      {creatingUser && (
        <Backdrop
          open={creatingUser}
          className="FormCreateUser-backdrop-loading-create"
        >
          <CircularProgress
            thickness={5}
            className="FormCreateUser-circular-loading-create"
          />
        </Backdrop>
      )}
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Typography className="FormCreateUser-title">
            Complete your profile information to get started.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <UserInformation
            payloadValidations={payloadValidations}
            payload={payload}
            setPayload={setPayload}
            setPayloadValidations={setPayloadValidations}
            scacValidate={scacValidate}
            setScacValidate={setScacValidate}
            handleCheckScacCode={handleCheckScacCode}
            handleCleanInformation={handleCleanInformation}
            nameRegex={nameRegex}
            cityDistrictRegex={cityDistrictRegex}
            stateProvinceRegex={stateProvinceRegex}
          />
        </Grid>

        <Grid xs={12}>
          <Divider />
        </Grid>
        <Grid xs={12}>
          <Typography className="FormCreateUser-title">
            Contact Information
          </Typography>
        </Grid>
        <Paper elevation={3} className="FormCreateUser-paper">
          <Grid xs={12}>
            <BillingInformation
              payload={payload}
              setPayload={setPayload}
              payloadValidations={payloadValidations}
              setPayloadValidations={setPayloadValidations}
              scacValidate={scacValidate}
              contactNameRegex={contactNameRegex}
              emailRegex={emailRegex}
            />
          </Grid>
          <Grid xs={12}>
            <Divider />
          </Grid>
          <Grid xs={12}>
            <STInformation
              payload={payload}
              setPayload={setPayload}
              payloadValidations={payloadValidations}
              setPayloadValidations={setPayloadValidations}
              scacValidate={scacValidate}
              contactNameRegex={contactNameRegex}
              emailRegex={emailRegex}
            />
          </Grid>
          <Grid xs={12}>
            <Divider />
          </Grid>
          <Grid xs={12}>
            <DispatchInformation
              payload={payload}
              setPayload={setPayload}
              payloadValidations={payloadValidations}
              setPayloadValidations={setPayloadValidations}
              scacValidate={scacValidate}
              contactNameRegex={contactNameRegex}
              emailRegex={emailRegex}
            />
          </Grid>
        </Paper>
        <Grid xs={12}>
          <Divider />
        </Grid>
        <Grid xs={12} className="FormCreateUser-checkbox-share-empties-grid">
          <Checkbox
            color="primary"
            checked={payload.Carrier.AcceptShareEmpties}
            onChange={(e) => {
              setPayload({
                ...payload,
                Carrier: {
                  ...payload.Carrier,
                  AcceptShareEmpties: e.target.checked,
                },
              });
            }}
          />
          <Typography className="FormCreateUser-checkbox-description">
            I accept to share my empties with other carriers in the network
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Button
            variant="contained"
            fullWidth
            className="FormCreateUser-button"
            onClick={() => handleCreateUserApi()}
          >
            Create Account
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default FormCreateUser;
