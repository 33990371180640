import { axiosInstance } from "./appInsights";
const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
  "Content-Type": "application/json",
};
export const createUser = async (payload) => {
  const config = {
    headers,
    url: process.env.REACT_APP_CREATE_USER,
    data: (payload = { payload }),
    method: "POST",
    nameEvent: "CreateUser-createUser",
  };

  try {
    const response = await axiosInstance(config);
    return response.data;
  } catch (err) {
    console.log(err);
    return err.response.data;
  }
};

export const getCarrierByScac = async (scac) => {
  const config = {
    headers,
    url: process.env.REACT_APP_GET_CARRIER_BY_SCAC,
    params: {
      ScacCode: scac,
    },
    method: "GET",
    nameEvent: "CreateUser-getCarrierByScac",
  };

  try {
    const response = await axiosInstance(config);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};
