import { Button } from "@mui/material";
import "./YardOwnerButton.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const YardOwnerButton = () => {
  function handleClick() {
    window.history.pushState({}, "", "/yms-admin/create-yard-owner");
  }
  return (
    <Button
      className="CreateUser-YardOwnerButton-button"
      variant="text"
      color="primary"
      startIcon={<ArrowBackIcon />}
      onClick={(e) => handleClick()}
    >
      Go to Yard Owner Form
    </Button>
  );
};

export default YardOwnerButton;
